<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <PageHeader :title="title" class="pb-3" />
        <v-data-table
          :headers="headers"
          :items="empresas"
          class="elevation-1"
          :search="search"
          :loading="isLoading"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-row>
                <v-col cols="10">
                  <v-text-field
                    v-model="search"
                    :append-icon="searchIcon"
                    label="Buscar"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
          <template v-slot:[`item.obrasSociales`]="{ item }">
            <v-icon
              v-if="item.obrasSociales === null ? false : true"
              small
              color="primary"
            >
              {{ checkIcon }}
            </v-icon>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip left v-if="canEdit">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="openModal(item)"
                >
                  {{ editIcon }}
                </v-icon>
              </template>
              <span>Editar relación</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-7 px-0"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-if="openModalEdit"
      v-model="openModalEdit"
      max-width="40%"
      @keydown.esc="closeAndReload"
      persistent
    >
      <EditRelacionConveniosEmpresas
        :editRelacionItem="editRelacionItem"
        @closeAndReload="closeAndReload"
      ></EditRelacionConveniosEmpresas>
    </v-dialog>
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import GoBackBtn from "@/components/shared/GoBackBtn";
import EditRelacionConveniosEmpresas from "@/components/modules/convenios/configuracion/EditRelacionConveniosEmpresas.vue";
import Ayuda from "@/components/shared/Ayuda.vue";

export default {
  components: { PageHeader, EditRelacionConveniosEmpresas, GoBackBtn, Ayuda },
  name: "RelacionConveniosEmpresas",
  data() {
    return {
      editIcon: enums.icons.EDIT,
      searchIcon: enums.icons.SEARCH,
      title: enums.titles.RELACION_DE_CONVENIOS_EMPRESAS,
      checkIcon: enums.icons.CHECK_OUTLINE,
      routeToGo: "ConfiguracionConvenios",
      search: "",
      isLoading: false,
      showExpand: false,
      showHelp: false,
      showIcon: true,
      allowedActions: null,
      canEdit: false,
      openModalEdit: false,
      empresas: [],
      editRelacionItem: {},
      headers: [
        {
          text: "Empresa",
          value: "entFacNombre",
          sortable: false
        },
        {
          text: "Relación convenios",
          value: "obrasSociales",
          sortable: false,
          align: "center"
        },
        { text: "Acciones", value: "actions", sortable: false, align: "end" }
      ],
      allowedActions: null,
      optionCode: enums.webSiteOptions.RELACION_CONVENIO_EMPRESA
    };
  },
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.loadConvenioEmpresas();
  },
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
  },
  methods: {
    ...mapActions({
      getConvenioEmpresas: "configConvenios/getConvenioEmpresas",
      setAlert: "user/setAlert"
    }),
    setPermisos() {
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions
            .EDIT_RELACION_CONVENIO_EMPRESA:
            this.canEdit = true;
            break;
          default:
            break;
        }
      });
    },
    async loadConvenioEmpresas() {
      this.isLoading = true;
      const data = await this.getConvenioEmpresas();
      this.empresas = data;
      this.isLoading = false;
    },
    openModal(item) {
      this.openModalEdit = true;
      this.editRelacionItem = item;
    },
    closeAndReload() {
      this.openModalEdit = false;
      this.loadConvenioEmpresas();
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    }
  }
};
</script>
